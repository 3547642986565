// module name
$name: 'list';
$base: $style-prefix + $module + $name;

.#{$base} {
  margin-left: -2rem;
  margin-bottom: $spacing;

  &__item {
    position: relative;
    padding-left: 4.5rem;
    font-size: $font-base-fontsize;
  }

  &--checks & {

    &__item {
      &::after {
        font-family: 'FontAwesome';
        font-weight: 900;
        color: $color-brand;
        content: '\f00c';
        position: absolute;
        display: block;
        left: 2rem;
        top: 0rem;
        font-size: 1.8rem;
      }
    }

  }

}

@media (min-width: $media-desktop){
  .#{$base} {
    
    &__title {
      font-size: 3rem;
    }

    &--header {
      margin-bottom: $spacing-big;
    }

    &--header &__item {
      font-size: 2rem;
      margin-bottom: $spacing;
      padding-left: 5rem;

      &::after {
        font-size: 2.2rem;
      }
    }
  }
}
