// module name
$name: 'searchform';
$base: $style-prefix + $module + $name;

#s::-ms-input-placeholder { color: hsla(0, 0%, 100%, 0.8); }
#s:-ms-input-placeholder{ color: hsla(0, 0%, 100%, 0.8); }

.#{$base} {
  display: flex;
  margin-left: auto;
  // IE 11 styling.
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 40px;
    margin-top: -10px;

    svg {
      position: relative;
      height: 3rem;
      width: 3rem;
      z-index: 10;
      left: -3px;
      top: 3px;
    }
  }

  &__field {
    width: 0;
    padding-left: 0;
    border: 0 solid transparent;
    background-color: $color-brand-darker;
    border-radius: $border-radius 0 0 $border-radius;
    color: #FFF;
    transition: width 250ms ease;
    -webkit-appearance: none;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px $color-brand-darker inset;
      -webkit-text-fill-color: #FFF;
    }

    &::placeholder { color: hsla(0, 0%, 100%, 0.8) }
  }

  &__field.is-open-search {
    width: 15rem;
    padding-left: $spacing;

    & + button {
      border-radius: 0 $border-radius $border-radius 0;
    }

  }

}

@media print and (color) {
  .#{$base} {
    display: none;
  }
}
