// module name
$name: 'icons-box';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  padding: 0 $spacing;
  margin-bottom: $spacing-section;

  &__title {
    @include grid-container($grid-content);
    color: $color-brand;
    margin: auto auto $spacing-big;
    font-weight: $font-strong;
    font-size: 2rem;
  }

  &__paragraph {
    @include grid-container($grid-content);
    margin-bottom: $spacing-big;
  }

  &__icon {

    &Container {
      display: flex;
      flex-direction: column;
      margin-bottom: $spacing-small;

      &--mobile {
        display: none;
      }
    }

    svg {
      max-height: 5rem;
      margin-bottom: $spacing-small;

      path {
        fill: $color-brand-lighter;
      }
    }

    &Title {
      flex: 1 0 0;
      font-size: 1.6rem;
      color: $color-brand;
      font-weight: $font-bold;
      margin-bottom: $spacing-big;
    }

  }

  &__button {
    @include button($width: auto);
    margin: auto;
  }
}

@media screen and (min-width: $media-desktop) {

  .#{$base} {

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &__icon {

      &Container {

        &--mobile {
          display: flex;
        }
      }
    }

    &__paragraph {
      margin-bottom: $spacing-big;
    }

    &__iconContainer {
      padding: 0 $spacing-big;
      margin-bottom: $spacing-big;
    }

  }
}
