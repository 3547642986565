@mixin grid($col: 1, $col-break: 1, $gap: $grid-gap, $default-break: 600px) {
  display: grid;
  grid-gap: $gap;
  grid-template-rows: auto;
  grid-template-columns: repeat($col, 1fr);

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
    width: 100%;
    height: auto;
  }

  @media (min-width: $default-break) {
    grid-template-columns: repeat($col-break, 1fr);
  }
}

@mixin grid-container($size: $grid-container) {
  max-width: $size;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
