@mixin page_header($height: 20%) {
  content: '';
  display: block;
  position: absolute;
  top: $menu-bar-height;
  height: $height;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
}

@mixin page_header_containers($height: 20%) {
  &::before {
    @include page_header($height);
    z-index: -1;
    background-size: cover;
    background-position: center center;
    opacity: .2;
  }

  &::after {
    @include page_header($height);
    z-index: -2;
    background-color: $color-brand;
  }
}

@mixin box($level: 1) {
  border-radius: $border-radius;
  box-shadow: 0px 0px 17px hsla(0, 0%, 0%, 0.15);
}

@mixin seperator($color: $color-primary, $position: 'after') {

  @if $position == 'after' {
    &::after {
      content: '';
      display: block;
      width: calc(100% - #{$spacing-big});
      height: 1px;
      margin: auto;
      background-color: $color;
      opacity: 0.1;
    }
  }

  @if $position == 'before' {
    &::before {
      content: '';
      display: block;
      width: calc(100% - #{$spacing-big});
      height: 1px;
      margin: auto;
      background-color: $color;
      opacity: 0.1;
    }
  }

}

@mixin overlay($color: hsla(0, 0%, 0%, 0.5), $height: 40%) {
  &::after {
    content: "";
    height: $height;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    background: -webkit-linear-gradient(hsla(0, 0%, 0%, 0) 0%, $color 100%);
    background: -o-linear-gradient(hsla(0, 0%, 0%, 0) 0%, $color 100%);
    background: linear-gradient(hsla(0, 0%, 0%, 0) 0%, $color 100%);
    opacity: 1;
    z-index: 1;
  }
}

@mixin shadow() {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
}

@mixin hover() {
  &:hover {
    transform: scale(1.03);
    box-shadow: 0 10px 80px rgba(0, 0, 0, 0.15);
  }
}

@mixin field() {
  border-radius: $border-radius;
  border-radius: $border-radius;
  font-size: $font-paragraph-size;
  padding: $spacing;
  background-color: #FFF;
  border: 1px solid $color-gray;
  margin-bottom: $spacing;
}

@mixin animate($time: 1s) {
  transition: $time all ease;
}
