// module name
$name: 'table';
$base: $style-prefix + $module + $name;

.#{$base} {
 table-layout: fixed;
 width: 100%;
 border-spacing: 0;
 margin-bottom: $spacing-big;

 &__head {
   padding: $spacing;
   text-align: left;
   border-bottom: 2px solid $color-brand;

   &:first-of-type {
     padding-left: 0;
   }
 }

 &__cell {
   padding: $spacing-small $spacing;
   border-bottom: 1px solid transparentize($color-secondary, 0.9);

   &:first-of-type {
    padding-left: 0;
  }
 }
}