// component name
$name: 'box-general';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #FFF;
  padding: $spacing $spacing $spacing-big;
  margin-bottom: $spacing-big;
  box-shadow: 0 0 17px rgba(0,0,0,.15);

  &__title {
    margin-top: $spacing;
    font-size: 1.8rem;
  }
}

@media (min-width: $media-desktop) {
  .#{$base} {
    &__title {
      margin-top: $spacing !important;
      margin-bottom: $spacing !important;
      font-size: 1.8rem !important;
    }
  }
}
