// module name
$name: 'rating';
$base: $prefix + $module + $name;

.#{$base} {
  position: relative;
  z-index: 2;
  margin-bottom: auto;
  user-select: none; 

  &--center {
    text-align: center;
    .#{$base}__container  {
      text-align: center;
    }
  }

  &--single {
  margin-bottom: $spacing-big;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

    .#{$base}__item {
      font-size: $review-small;
    }

  }

  &__container {
    position: relative;
    padding: 0;
    z-index: 2;
  }

  &__item {
    padding: 0;
    display: inline-block;
    transition: color 0.5s ease;
    color: hsla(0, 0%, 0%, 0.4);
    text-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0.8);

    &--filled {
      color: $rating-color;
    }

  }

  &__link {
    color: $color-brand;
    text-decoration: none;
  }

  &__number {
    display: none;
  }

  &__form & {
    &__item {
      font-size: $review-big;
      text-shadow: unset;
      cursor: pointer;
    }
  }

  #rating_1:checked ~ &__container &__item:nth-child(1) { color: $rating-color; }
  #rating_2:checked ~ &__container &__item:nth-child(-n+2) { color: $rating-color; }
  #rating_3:checked ~ &__container &__item:nth-child(-n+3) { color: $rating-color; }
  #rating_4:checked ~ &__container &__item:nth-child(-n+4) { color: $rating-color; }
  #rating_5:checked ~ &__container &__item { color: $rating-color; }

  #rating_1:hover ~ &__container &__item:nth-child(1) { color: $rating-color; }
  #rating_2:hover ~ &__container &__item:nth-child(-n+2) { color: $rating-color; }
  #rating_3:hover ~ &__container &__item:nth-child(-n+3) { color: $rating-color; }
  #rating_4:hover ~ &__container &__item:nth-child(-n+4) { color: $rating-color; }
  #rating_5:hover ~ &__container &__item { color: $rating-color; }

}

@media (min-width: $media-desktop) {
  .#{$base} {

    &__number {
      display: inline-block;
      margin: .5rem $spacing 0;
      font-weight: bold;
      color: #000;
    }

    &--single {
      justify-content: left;
      margin-bottom: $spacing-big;
    }

    &--single & {
      &__item {
        font-size: $review-big;
        text-shadow: unset;
      }

    }
  }
}
