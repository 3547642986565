// module name
$name: 'label';
$base: $prefix + $module + $name;

.#{$base} {
  background-color: $color-brand;
  border-radius: $border-radius;
  padding: 0 $spacing-small;
  margin: $spacing-small;
  font-family: $font-special-family;
  font-weight: normal;
  font-size: 1.2rem;
  color: #FFF;
}
