// component name
$name: 'sidebar';
$base: $style-prefix + $layout + $name;

.#{$base} {
  flex: 1;
  min-width: 28rem;
  flex-basis: 100%;
  padding: 0 2rem $spacing-section;
  
  &--true {
    .cd-mod-breadcrumbs, 
    .cd-com-pageheader__titleContainer, 
    .plate--container {
      margin-left: 0;
    }

    .cd-com-pageheader--small .cd-com-pageheader__titleContainer {
      padding-left: 1rem;
    }

    .cd-com-pageheader--small, .cd-com-pageheader__titleContainer {
      padding-left: 0;
    }

    .cd-com-background::before, .cd-com-background::after {
      margin: 0 auto;
      width: calc(100% - 4rem);
    }

    .plate--container {
      max-width: none;
    }

    .cd-mod-service {
      order: 1;
      flex: 1;
      min-width: 45%;
      flex-basis: 100%;
    }

    .cd-com-gallery--grid {
      padding: 0 1rem;

      .cd-com-gallery__item {
        flex: 1;
        flex-basis: 50%;
        max-width: 100%;
      }
    }
    
    .cd-com-background {
     &__1, &__2, &__3 {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    .rack-mod-button {
      max-width: 32rem; 
    }

    .page {
      display: flex;
      max-width: 120rem;
      margin: auto;
      flex-direction: column;
    }

    @media (min-width: $media-desktop ) {
      .page {
        flex-direction: row;
      }

    }

  }

  @media (min-width: $media-desktop ) {
    flex-basis: 100%;
    padding-bottom: 0;
    max-width: 30%;
  }

}
