// module name
$name: 'form';
$base: $style-prefix + $module + $name;

.#{$base} {
  margin-bottom: $spacing-section;

  &--marginless {
    margin: 0;
  }

  &__single {
    width: 100%;
    @include field;

  }

  &__textarea {
    width: 100%;
    min-height: 15rem;
    @include field;
  }

  &__button {
    @include button($padding: 1rem);
  }

  &__row {

  }

  &__column {
    @include grid(1, 3);
    margin-bottom: $spacing;
  }

  &__column & {
    &__button, &__single {
      margin-bottom: 0;
    }
  }

  &__label {
    font-family: $font-special-family;
    font-weight: $font-bold;
    margin-bottom: $spacing;
  }

}
