// component name
$name: 'background';
$base: $style-prefix + $component + $name;

.#{$base} {
  margin-bottom: $spacing-big;
  
  &--image {
    padding-top: $spacing-section * 2;
    padding-bottom: $spacing-big * 2;
  }
  
  @mixin background_element($color) {
  position: relative;
  padding-top: $spacing-section;
  padding-bottom: $spacing-big;
  overflow-x: hidden;

    &::after {
      content: '';
      z-index: 0;
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      background-color: $color;
    }

    &::before {
      content: '';
      z-index: -1;
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      background-size: cover;
      background-position: center center;
      opacity: .2;
    }
  
    &::after {
      z-index: -2;
      background-color: $color;
    }

    @media (min-width: 1000px) {
      &::after, &::before {
        border-radius: $border-radius * 2;
        max-width: $background-wide-large;
        width: calc(100% - 6rem);
      }
    }

    h1:first-child, h2:first-child  {
      margin-top: 0;
    }

  }

  &__1, &__2, &__3 { margin-bottom: $spacing-section; }

  &__1 { @include background_element(transparentize($color-secondary, 0.9)); }
  &__2 { @include background_element(transparentize($color-brand, 0.9)); }
  &__3 { @include background_element(transparentize($color-primary, 0.9)); }


  &__1.#{$base}--solid { 
    @include background_element($color-secondary); 
    color: #FFF; 
    .cd-mod-table__head { border-color: #FFF; }
    .cd-mod-table__cell { border-color: #FFF; }
  }
 
  &__2.#{$base}--solid { 
    @include background_element($color-brand); 
    color: #FFF; 
    .cd-mod-table__head { border-color: #FFF; }
    .cd-mod-table__cell { border-color: #FFF; }
    a {
      color: #000;
      &:hover, &:visited {
        color: rgba(255,255,255,0.8);
      }
    }
  }
  
  &__3.#{$base}--solid { 
    @include background_element($color-primary); 
    color: #FFF; 
    .cd-mod-table__head { border-color: #FFF; }
    .cd-mod-table__cell { border-color: #FFF; }
    a {
      color: #FFF !important;
      &:hover, &:visited {
        color: rgba(255,255,255,0.8) !important; 
      }
    }
  }

  &--no-margin { margin-bottom: 0 }

} 

@media (min-width: $media-tablet) {
  .#{$base} {

    

    &--no-margin { margin-bottom: $spacing-section; }

  }
}