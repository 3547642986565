// module name
$name: 'prices';
$base: $style-prefix + $module + $name;

.#{$base} {
  width: 100%;
  position: relative;
  margin-bottom: $spacing-section;
  color: $font-base-color;

  &__container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: $spacing-big;
  }

  &__heading {
    font-size: 1.8rem;
    font-weight: $font-bold;
    margin-bottom: $spacing-big; 
    display: block;
    width: 100%;
    text-align: center;
    flex-basis: 100%;
  }

  &__block {
    min-width: 26rem;
    padding: $spacing $spacing-big $spacing-big;
    background-color: #FFF;
    border-radius: $border-radius;
    margin: $spacing;
    border: 2px solid transparentize($color-secondary, 0.9);
    ul {
      margin-top: 0;
    }
  }

  &__title {
    display: block;
    color: $color-secondary;
    font-size: 1.6rem;
    font-weight: $font-bold;
    margin-bottom: $spacing;
  }

  &__text {
    display: block;
    color: $color-secondary;
    line-height: 2rem;

    &--italic {
      font-style: italic;
    }

  }

  &__value {
    display: inline-block;
    color: $color-secondary;
    font-size: 2rem;
    font-weight: $font-bold;
    margin-bottom: $spacing;
  }

}

@media (min-width: $media-desktop) {
  .#{$base} {
    &__block {
      max-width: 25%;
    }

    &__title {
      font-size: 2rem;
    }

    &__text {
      font-size: 1.4rem;
    }

    &__heading {
      font-size: 2.5rem;
      margin-bottom: $spacing-section; 
    }

    &__value {
      font-size: 2.5rem;
    }
  }
}