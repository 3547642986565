// module name
$name: 'pageheader';
$base: $style-prefix + $component + $name;


.#{$base} {
  border-radius: 2rem;
  padding: $spacing-section $spacing 0;
  line-height: 0;
  @include grid-container($grid-content);
  @include page_header_containers();

  &--small::after, &--small::before {
    display: none;
  }

  &__container {
    position: relative;
    @include grid-container();
  }


  &__title {
    position: relative;
    display: inline;
    margin-top: 0;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: $spacing-section;
    left: -.1rem;
    position: relative;
    
    &::before {
      content: '';
      background-color: $color-brand;
      position: absolute;
      left: -1.9rem;
      bottom: -1rem;
      width: calc(100% + 2rem);
      height: .6rem;
      border-radius: 0 1rem 1rem 0; 
    }

    &Container {
      @include grid-container($grid-content);
      margin-bottom: 4rem;
     }

  }

  &__image {
    border-radius: $border-radius;
    object-fit: cover;
    min-height: 25rem;
    max-height: 30rem;
    width: 100%;
    @include shadow;
    margin-bottom: $spacing-big;
  }

  &--homepage {
    @include grid-container($grid-container);

    .cd-mod-card {
      max-width: 40rem;
      margin-left: auto;
      margin-right: auto;
    }

    .cd-mod-card__title {
      font-size: 2.2rem;
    }

  }

  &--homepage & { 
   
    &__title {
      display: block;
      position: relative;
      font-size: 2.7rem;
      font-weight: $font-bold;
      color: #FFF;
      line-height: 3.2rem;
      z-index: 1;
      padding: 0 $spacing;
      margin-bottom: $spacing-section;

      &:before, &:after {
        display: none;
      }
    }
    
    &__image {
      width: 100%;
      height: 18rem;
      overflow: hidden;
      border-radius: 1rem 1rem 0 0;
      margin-bottom: 0;
      max-height: none;
    }
    
    &__container {
      position: relative;
      @include grid-container($grid-container);
      max-width: 40rem;
      padding: 0;
    }
    
  }

  &--homepage::before, &--homepage::after  {
    height: 60vh;
  }

}

@media (min-width: $media-tablet) {
  .#{$base} {
    padding: $spacing-section $spacing-big 0;

    &--small {
      @include page_header_containers($height: 6rem);
      @include grid-container();
      padding: 0 $spacing-big 0;
      
      & .cd-mod-breadcrumbs {
        padding: 0 $spacing $spacing-big $spacing;
      }

    }
  
    &--small::before, &--small::after {
      display: block;
      content: '';
    }

    &__title {

      &::before {
        border-radius: 1rem; 
      }

    }
  
    &--small & {
      &__title {
        display: inline;
        line-height: 100%;
        
        &Container {
          @include grid-container($grid-content);
          padding: 0 2rem;
          margin-bottom: 4rem;
         }
  
      }

      &__container {
        @include grid-container();
        padding: 0 $spacing;        
      }
    }
  }

}

@media (min-width: $media-tablet) {
  .#{$base} {

    .cd-mod-card {
      max-width: 40rem;
      margin-left: auto;
      margin-right: auto;
    }

    &__title {
      text-align: center;

      &::before {
        left: .1rem;
        width: calc(100% - .1rem); 
      }
    }

    &--small {
      padding: 0;

      &::before, &::after {
        height: 6rem;
      }
    }

    &--small & {
      &__title {

        &Container {
          padding: 0 1rem;
        }

      }
    }

    &--homepage {
      &::before, &::after {
        height: 40vh;
      }
    }

    &--homepage & {

      &__container {
        max-width: 40rem;
        margin: auto;
        padding: 0;
      }

    }
  }
}

@media screen and (min-width: $media-desktop) {

  .#{$base} {

    .cd-mod-card {
      max-width: none;
      margin: 1rem 7rem 4rem 1rem;
      width: 80%;
    }

    .rack-mod-button {
      width: 35rem;
    }
  
    .rack-mod-button__terms {
      max-width: 35rem;
    }

    &__title {
      font-size: 3.5rem;
      text-align: left;

    
      &::before {
        height: .6rem;
      }
    }

    &::before, &::after {
      top: 8.5rem;
      width: calc(100% - 2rem);
      max-width: $background-wide-large;
      margin: 0 auto;
      border-radius: $border-radius * 2;
    }


    &--small {

      &::before, &::after {
        height: 6rem;
      }
    }

    &--small & {
      &__title {

        &Container {
          padding: 0 2rem;
        }
      }
    }

    &--homepage {
      @include grid-container($grid-container);

      &::before, &::after {
        height: 60vh;
      }

    }


    &--homepage & {

      &::after, &::before {
        height: 60rem;
      }

      &__title {
        padding: 16rem 1rem 10rem;
        font-size: 5rem;
        line-height: 5rem;

        &::before {
          bottom: -1.5rem;
        }
      }

      &__container {
        max-width: none;
      }

      &__image {
        position: absolute;
        right: 5rem;
        bottom: -26rem;
        width: 40vw;
        max-width: 50rem;
        height: 65rem;
        margin: $spacing-big;
        z-index: 3;
        border-radius: $border-radius;
        @include shadow;
      }
    }

  }

}

