// module name
$name: 'heading';
$base: $prefix + $module + $name;

@mixin heading {
  display: block;
  padding: 0 $spacing $spacing $spacing;
  color: $heading-color;
  font-weight: $heading-weight;
  font-family: $heading-font;
  line-height: $font-heading-lineheight;
  @include grid-container();
}

.#{$base} {

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &__h1 {
    font-size: $heading-1-font-size;
    @include heading;
  }

  &__h2 {
    font-size: $heading-2-font-size;
    @include heading;
  }

  &__h3 {
    font-size: $heading-3-font-size;
    @include heading;
  }
}
@media (min-width: $media-desktop) {
  .#{$base} {

    &__h1 {
      font-size: $heading-1-font-size * 2;
      @include heading;
      padding: 0 $spacing $spacing-section $spacing;
    }

    &__h2 {
      font-size: $heading-2-font-size * 2;
      @include heading;
      padding: 0 $spacing $spacing-big $spacing;
    }

    &__h3 {
      font-size: $heading-3-font-size * 2;
      @include heading;
      padding: 0 $spacing $spacing-big $spacing;
    }

  }

}
