// module name
$name: 'header';
$base: $style-prefix + $layout + $name;

.#{$base} {
  margin-bottom: $spacing-section;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: $menu-bar-height;
    background-color: $color-brand;
    z-index: 80;
  }

  &--homepage {
    margin-top: -$menu-bar-height;
    margin-bottom: $spacing-section * 2;
  }
}

@media screen and (min-width: $media-desktop) {
  .#{$base} {
    position: relative;
    padding-top: 8.5rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 8.5rem;
      background-color: $color-brand;
      z-index: -1;
    }

    &__decoration {
      display: block;
      position: absolute;
      right: 0;
      bottom: -49px;
      z-index: -1;
    }

    &--homepage {
        padding: 0;
        margin-top: 0;

        &::before {
        height: 90%;
      }
    }

    &--homepage & {

    }

    &::after {
      display: none;
    }

    &__container {
      display: flex;
    }

  }
}

@media print {
  .#{$base} {
    padding-top: 0;

    &::before {
      display: none;
    }

    &__decoration {
      display: none;
    }
  }
}
