* {
  box-sizing: border-box;
}

html, body {
  font-size: $font-size;
  margin: 0;
  counter-reset: section;
}

body {
  padding-top: $menu-bar-height;
  font-family: $font-base-family;
  line-height: $font-base-lineheight;
  font-size: $font-base-fontsize;

  &.is-open {
    height: 0;
    overflow: hidden;
  }
}

p, ul, figure {
  margin: 0;
  padding: 0;
}

h1 { font-size: $heading-1-font-size; }
h2 { font-size: $heading-2-font-size; }
h3 { font-size: $heading-3-font-size; }

ul li {
  list-style: none;
}

p {
  line-height: $font-base-lineheight;
}

textarea {
  max-width: 100%;
}

[data-print="only"] {
  display: none !important;
}

@media screen and (min-width: $media-desktop) {
  body {
    padding-top: 8.5rem;
    font-size: $desktop-fontsize;
  }
}

@media print {
  body {
    padding-top: 0;
    font-size: $desktop-fontsize;
  }
}
