// module name
$name: 'breadcrumbs';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: none;
}

@media (min-width: $media-desktop) {

  .#{$base} {
    display: block;
    padding: 0 $spacing-big 3rem 0;
    max-width: 780px;
    margin: auto;
    
    &__item {
      text-decoration: none;
      color: #000;
      @include animate(200ms);
  
      &--active {
        font-weight: $font-bold;
      }
      
      &:hover {
        opacity: 0.4;
      }
    }

    &__serp {
      padding: 0 .2rem;
      font-size: 1.2rem !important;
      position: relative;
      top: -.1rem;
    }
  }

}
