// module name
$name: 'subfooter';
$base: $style-prefix + $layout + $name;

.#{$base} {
  padding: 0 $spacing;
  text-align: center;
  display: flex;
  flex-direction: column;

  &__title {
    color: #FFF;
    margin-bottom: $spacing-big;
  }

  &__description {
    color: #FFF;
    margin-bottom: $spacing-big;
  }

  &__button {
    margin: $spacing-section 0;
    @include button($width: auto, $style: $color-brand-lighter);
    color: #FFF;
    flex-basis: 100%;
    margin: $spacing auto;
  }

  &__textlink {
    @include textlink;
    width: 100%;
    text-align: center;
    color: #FFF;
  }

}

@media (min-width: $media-desktop) {
  .#{$base} {

    &__title {
      font-size: 5rem;
    }

  }
}
