// component name
$name: 'alerts';
$base: $style-prefix + $module + $name;

@keyframes fade {
  from { opacity: 1 }
  to { opacity: 0 }
}

.#{$base} {  

  &__main {
    position: relative;
    bottom: 1rem;
    z-index: 1000;
    background-color: #a90f0f;
    width: 100%;
    left: 0;
    text-align: center;
    padding: $spacing;
    color: white;
    margin-bottom: $spacing;
    border-radius: $border-radius;
    animation: fade 0.5s 1;
    animation-fill-mode: forwards;
    animation-delay: 5s;

    &--notice {
      background-color: #18a244;
    }
  }

  &__item {
    color: #a90f0f;
    
    &:first-letter {
      text-transform: uppercase;
    }
  }
}