// module name
$name: 'card';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: block;
  flex: 1;
  position: relative;
  background-color: #FFF;
  padding: $spacing-big $spacing-big 0;
  border-radius: $border-radius;
  line-height: $font-base-lineheight;
  margin-bottom: $spacing-big;
  z-index: 2;
  flex-basis: 100%;
  @include shadow; 
  
  &--flattop {
    border-radius: 0 0 $border-radius $border-radius; 
  }

  &--homepage {
    border-radius: 0 0 $border-radius $border-radius;
    padding: 3rem 3rem 1rem;
    margin: 0 0 $spacing-section;

    &__title {
      font-size: 2.6rem;
      margin-bottom: $spacing * $desktop-factor;
    }

  }
  
  &--numbering {
    padding-left: calc(6rem + #{$spacing-big});    

    &::after {
      position: absolute;
      counter-increment: section;
      content: '#' counter(section);
      height: 5rem;
      width: 6rem;
      background: $color-brand;
      color: #FFF;
      text-align: center;
      line-height: 5rem;
      font-weight: $font-bold;
      font-size: 1.8rem;
      top: 0;
      left: 0;
      border-radius: $border-radius 0;
    }
  }

  &--big {
    padding-right: $spacing + 4rem;
    transition: all 200ms ease-in-out;

    @include hover;
    &::after {
      font-family: 'FontAwesome';
      position: absolute;
      content: '\f105';
      height: 4rem; 
      width: 4rem;
      background: $color-brand;
      color: #FFF;
      text-align: center;
      line-height: 4rem;
      font-size: 1.8rem;
      right: 0;
      bottom: 0;
      border-radius: $border-radius 0;
    }
  }

  &__section {
    @include grid-container();
    padding: 0 2rem;
  }

  &__container {
    display: flex;    
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: $spacing-section;
  }

  &__container &--numbering:last-of-type {
    background-color: $color-brand;
    color: #FFF;
    padding-left: $spacing-big;
    transition: all 200ms ease;
    @include hover;

    &::after {
      display: none;
    }

    &::before {
      font-family: 'FontAwesome';
      position: absolute;
      content: '\f105';
      height: 4rem;
      width: 4rem;
      background-color: #FFF;
      color: $color-brand;
      text-align: center;
      line-height: 4rem;
      font-size: 1.8rem;
      right: 0;
      bottom: 0;
      border-radius: $border-radius 0;
    }

  }

  &__container &--numbering:last-of-type & {

    &__title {
      color: #FFF;
    }
  }

  &__title {
    display: block;
    font-size: 1.8rem;
    font-weight: $font-bold;
    padding-bottom: $spacing-big;
    color: $font-base-color;
    text-decoration: none;

    & + .#{$base}__desc {
      display: block;
      margin-top: -15px;
    }

    &:before { 
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__desc {
    display: inline-block;
    margin-bottom: $spacing-big;
  }

}

@media (min-width: $media-desktop) {
  .#{$base} {
    flex: inherit;
    width: calc((100% / 3) - 2rem); 
    margin-left: 2rem;
    margin-bottom: 3rem;

    &__container {
      margin-left: -2rem;
    }

    &--homepage {
      border-radius: $border-radius;
      box-shadow: none;
      max-width: none;
      padding: $spacing-big * $desktop-factor;
      margin: 0 7rem $spacing-section 0;
  
      &__title {
        font-size: 2.6rem;
        margin-bottom: $spacing * $desktop-factor;
      }
  
    }


  }
}