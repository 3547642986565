// module name
$name: 'company';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: flex;
  flex-direction: column;
  flex: 0.5 0.5 0;

  &__logo {
    max-width: 100%;
    width: auto;
    margin: auto;
    max-height: 10rem;
  }

  &__button {
    margin: $spacing-section 0;
    @include button($width: auto);
    flex-basis: auto;
    margin: $spacing auto auto;
  }

  &__textlink {
    @include textlink;
    width: 100%;
    text-align: center;
  }

  &__box {
    display: flex;
    max-width: 25%;
    flex: 1 1 0;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #FFF;
    padding: $spacing $spacing $spacing-big;
    margin-bottom: $spacing-big;
    box-shadow: 0 0 17px rgba(0,0,0,.15);
  }

}
