// module name
$name: 'button';
$base: $prefix + $module + $name;

.#{$base} {
  display: block;
  position: relative;
  @include button($width: auto);
  margin-bottom: $spacing-big;

  &__inline {
    @include button($width: auto);
    display: inline-block;
    margin-bottom: $spacing;
  }

  &__right {
    @include button($width: auto);
    display: inline-block;
    float: right;
    margin-bottom: $spacing;
  }

  &__full {
    @include button($width: 100%);
    display: block;
    margin-bottom: $spacing;
  }

  &__center {
    @include button($width: auto);
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: $spacing;
  }

  &__terms {
    display: block;
    text-align: center;
    color: #000;
    opacity: .5;
    font-size: 90%;
    padding-bottom: $spacing;
  }

  & + &__terms  {
    line-height: 90%;
  }

    &--big {
    font-size: 1.6rem;
    @include button($width: 100%);
  }

  &--center {
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
  }

  &--secondary {
    @include button($style: $color-secondary, $width: auto);
  }

}
