// module name
$name: 'subheader';
$base: $style-prefix + $layout + $name;

.#{$base} {
  background-color: #191968;
  padding: ($spacing-section) $spacing-big;
  margin-top: -$spacing-section;
  margin-bottom: $spacing-section;

  &__title {
    margin: 0;
    font-size: 3rem;
    line-height: 4rem;
    color: #FFF;
    @include grid-container;
  }

}

@media (min-width: $media-desktop) {

  .#{$base} {
    padding: ($spacing-section * 2) $spacing-big;

    &__title {
      margin: 0;
      font-size: 6rem;
      line-height: 7rem;
      color: #FFF;
      @include grid-container;
    }

  }

}
