// module name
$name: 'footer';
$base: $style-prefix + $layout + $name;

.#{$base} {
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding-top: $spacing-section;
  color: #FFF;
  font-size: 1.4rem;

  &--mobile {
    display: block;
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-section;
    @include grid-container;
  }

  &__button {
    display: block;
    background-color: #1d1e20;
    border-radius: $border-radius;
    font-weight: $font-bold;
    padding: $spacing $spacing-big $spacing 4rem;
    margin-bottom: $spacing;
    position: relative;
    @include animate(200ms);

    p {
      font-weight: normal;
      line-height: $font-base-lineheight;
    }

    &:hover {
      background-color: darken($color-secondary, 20%);
    }
 

  }

  &__icon {
    position: absolute;
    left: 1.45rem;
    top: 1.45rem;
    font-size: 1.6rem !important;

    .fa-location-arrow {
      top: 1.35rem;
    }

    .fa-envelope {
      top: 1.5rem;
    }


  }

  &__logo {
    margin: 0 auto $spacing-section;
    max-width: 20rem;
    order: 3;

    &Container {
      display: flex;
    }
  }

  &__sub {
    display: block;
    width: 100%;
    position: relative;
    color: $font-base-color;
    padding: $spacing-big $spacing-small;
    text-align: center;
    z-index: 10;

    // Yoast SEO breadcrumbs links
    a {
      display: inline-block;
      color: $color-brand;
      text-decoration: none;
      padding: 0 $spacing-small !important;
      &:after {
        content: '\021E2';
        color: $font-base-color;
        padding-left: $spacing-small;
        margin-right: $spacing-small;
      }
    }
  }

  &__column {
    padding: 0 $spacing;
    margin-bottom: $spacing-big * 2;
    order: 2;

    a {
      color: #FFF;
      text-decoration: none;
    }

    iframe {
      margin-top: 20px;
      border-radius: $border-radius;
    }

    &Title {
      display: flex;
      color: #FFF;
      font-family: $font-special-family;
      font-size: 1.8rem;
      line-height: 1.5rem;
      font-weight: $font-bold;
      margin-bottom: $spacing-big;
    }

    & li {

      a {
        position: relative;
        display: block;
        width: 100%;
        padding: 0 0 $spacing;
        font-size: 1.4rem;
        transition: all 250ms ease;

        &:hover {
          opacity: 0.6;
        }

      }

    }

  }

  &__block {
    @include seperator($color: hsl(0, 0%, 100%), $position: 'before');
  }

  &__title {
    width: 100%;
    display: block;
    position: relative;
    padding: $spacing-big $spacing;
    font-size: 1.4rem;
    font-weight: $font-bold;
    color: #FFF;
    text-decoration: none;
  }

  &__copyright {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color:#1d1e20;
    padding-top: $spacing-big;
    font-size: 1.2rem;

    &Text {
      display: block;
      margin-top: 0;
      margin-bottom: $spacing;
      margin-left: $spacing;
    }

    svg {
      margin: 0 $spacing $spacing-section;
    }

  }

}

@media (min-width: $media-tablet) {
  .#{$base} {
    
    &__container {
      flex-direction: row;
      justify-content: flex-start;
    }    

    &__column {
      flex: 1;
      padding-left: 3rem;
      padding-right: 3rem;

      &Title {
        margin-bottom: $spacing;
      }

      &:first-of-type {
        padding-left: $spacing;
      }

      &:last-of-type {
        padding-right: $spacing;
      }
    }

    

  }
}

@media (min-width: $media-desktop) {
  .#{$base} {
    padding-top: $spacing-section;

    &--mobile {
      display: none;
    }

    &__copyright {
      flex-direction: row;
      justify-content: space-around;
      padding: 0 $spacing-big;
      font-size: 1.4rem;
      padding-top: $spacing-big;
      order: 3;

      &Text {
        order: 0;
        margin-top: 0;
      }

      &Artist {
        margin-left: auto;
      }

      svg {
        margin: 0 0 0 $spacing;
      }
    }

    &__logo {
      max-width: 300px;
      margin-left: $spacing-big;
      margin-bottom: 2rem;

      &Container {
        @include grid-container;
        display: block;
        order: 1;
        margin-bottom: $spacing;
      }
    }

    &__container {
      @include grid-container;
      max-width: 118rem;
      order: 2;
      flex-direction: row;
      justify-content: flex-start;

      &--space {
        justify-content: space-between;
      }
    }

    &__button {
      padding-left: 4.3rem;
    }

    &__column {
      max-width: 25%;
      flex: 1;
      padding: 0 3rem;

      li a {
        padding-bottom: 0;
      }

      &:first-of-type {
        padding-left: 1rem;
      }

    }

  }
}
