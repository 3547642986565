// module name
$name: 'element__paragraph';
$base: $plate-prefix + $name;

.#{$base} {
  counter-reset: div;

  p {
    margin-bottom: 2rem;
  }

  a {
    color: $color-brand;

    &:hover {
      color: darken($color-brand, 5%);
      text-decoration: none;
      
    }

    &:visited:hover {
      color: darken($color-brand, 10%);
      text-decoration: none;
    }

  }

  ul { 
    position: relative;
    padding-left: $spacing-big;
    margin-bottom: $spacing-big;
    margin-top: 0;
    
    li {
      position: relative;
      margin-bottom: .2rem;
      &::before {
        content: '';
        background-color: $color-brand;
        height: .8rem;
        width: .8rem;
        border-radius: 100%;
        position: absolute;
        left: -$spacing-big;
        top: 6.5px;
      }
    }
  }

  ol { 
    position: relative;
    padding-left: 3rem;
    margin-bottom: $spacing-big;
    list-style: none;
    margin-top: 0;
    
    li {
      position: relative;
      margin-bottom: .2rem;
      &::before {
        counter-increment: div;
        content: counter(div);
        background-color: $color-brand;
        text-align: center;
        line-height: 2.1rem;
        height: 2rem;
        width: 2rem;
        color: #FFF;
        border-radius: .5rem;
        position: absolute;
        left: -2.9rem;
        top: .25rem;
      }
    }
  }

} 

.plate--column {
  min-height: 0;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  .rack-mod-button {
    margin-bottom: $spacing-big;
  }
}

.plate--element__image img {
  margin-top: $spacing-big;
}

@media (min-width: $media-tablet) {
  .plate--column {
    min-height: 0;
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
    .rack-mod-button {
      margin-bottom: $spacing-big;
    }
  }
}

@media screen and (min-width: $media-desktop) {
  .#{$base} {
    ul li::before {
      top: 8.5px;
    }
  }

}

.page .plate--container {
  @include grid-container($grid-content);
}



body.is-user {
  .plate--column {
    min-height: 40px !important;
  }
}

.plate--page-content-wrapper {
  flex-basis: 100%;
}