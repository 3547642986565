// component name
$name: 'box-item';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #FFF;
  padding: $spacing $spacing $spacing-big;
  margin-bottom: $spacing-big;
  box-shadow: 0 0 17px rgba(0,0,0,.15);

  &__logo {
    flex: 1 1 0;
    flex-basis: 100%;
    text-align: center;
    padding-bottom: $spacing;
    margin-bottom: $spacing;
    border-bottom: 1px solid lighten($color-gray, 80);

    &Image {
      max-width: 100%;
      height: auto;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    color: $color-brand;
    margin-bottom: $spacing;
  }

  &__label {

  }

  &__value {
    font-weight: $font-bold;
  }

  &__button {
    @include button;
    max-width: 20rem;
    margin: auto;

    &Container {
      display: flex;
      flex-basis: 100%;
      min-width: 100%;
      flex-direction: column;
      flex: 1;
    }

  }

  &__textlink {
    @include textlink;
    width: 100%;
    text-align: center;
  }

}

@media (min-width: $media-desktop) {
  .#{$base} {
    align-items: center;
    padding: $spacing;
    height: 16rem;

    &__logo {
      display: flex;
      align-items: center;
      flex: 1 0 0;
      min-width: 15rem;
      height: 100%;
      margin-bottom: 0;
      padding-bottom: 0;
      padding: 0 $spacing-big 0 $spacing;
      margin-right: $spacing-big;
      border-right: 1px solid rgba(204,204,204,.25);
      border-bottom: 0px solid transparent;

    }

    &__column {
      flex: 1 0 0;
      flex-basis: auto;
      padding: 0 $spacing-big;
    }

    &__button {
      margin: 0 ;

      &Container {
        display: flex;
        justify-content: center;
        min-width: auto;
        flex: 1 0 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 $spacing 0 $spacing-big;
        margin-left: $spacing-big;
        border-left: 1px solid rgba(204,204,204,.25);
      }

    }
  }
}
