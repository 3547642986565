// module name
$name: 'cards';
$base: $prefix + $module + $name;

.#{$base} {
  @include grid-container;
  @include grid(2, 4);
  position: relative;
  padding: $spacing $spacing $spacing-section;
  margin-bottom: $spacing-section;
  line-height: 0;
  font-size: 0;

  &--big {
    @include grid(1, 3);
    justify-content: flex-end;
  }

  &--homepage {
    @include grid(1, 4);
    justify-content: flex-end;
  }

  &__column {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none;
    z-index: 2;
  }

  &__row {
    display: flex;
    flex-direction: row;
    padding: 0;
    list-style: none;
    justify-content: space-between;

    // IE 11 styling
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      padding: $spacing;
    }
  }

  &__card {
    position: relative;
    height: $card-height;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: $spacing $spacing 0;
    border-radius: $border-radius;
    overflow: hidden;
    transform: scale(1);
    @include overlay;
    @include card;

    &Link {
      margin-bottom: $spacing;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &Meta {
      color: #FFF;
      font-size: $font-meta-size;
      margin-bottom: $spacing;
      text-shadow: $font-shadow;
    }

    &Icon svg {
      width: 1.5rem;
      height: 1.5rem;
    }

    // IE 11 styling
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &Image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

  }

}

@media (min-width: $media-desktop) {

  .#{$base} {

    &__card {
      transition: all 200ms ease;
      cursor: pointer;
      @include hover;
      @include card-desktop;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &Link {
          padding: 0 10px;
        }
      }

      &:hover & {
        &Image {
          transform: scale(1.01);
        }
      }

    }

    &--homepage & {
      &__card:first-of-type {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 3;
        height: 100%;
      }
    }

  }
}
