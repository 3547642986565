// module name
$name: 'space';
$base: $style-prefix + $layout + $name;

.#{$base} {
  height: $spacing;

  &--small { height: $spacing-small; }
  &--big { height: $spacing-big; }
  &--section { height: $spacing-section; }

}
