@mixin button($style: $button-color-primary, $width: 100%, $padding: $button-padding) {
  position: relative;
  background-color: $style;
  padding: $padding;
  border-radius: $border-radius;
  text-decoration: none;
  font-family: $font-special-family;
  font-size: $font-base-fontsize;
  text-align: center;
  font-weight: $font-bold;
  border: 2px solid $style;
  white-space: nowrap;
  cursor: pointer;
  
  &::after {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f054";
  }
  -webkit-appearance: none;
  @include animate(200ms);

  @if $style == $button-color-primary {
    color: #FFF;
  }

  @if $style == $button-color-secondary {
    border-color: $style;
    color: $style;
    background-color: transparent;
  }

  @if $style == $button-color-disabled {
    color: darken($style, 20);
  }

  width: $width;

  @media screen and (min-width: $media-desktop) {
    font-size: 1.4rem;
    font-weight: $font-bold;
  }

  &:hover {
    background-color: darken($style, 10);
    border: 2px solid darken($style, 10);
    color: #FFF;
  }

}

@mixin textlink() {
  color: $color-brand-lighter;
  padding: $spacing 0;
  font-weight: $font-bold;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 90%;
}
