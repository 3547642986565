// component name
$name: 'form';
$base: $style-prefix + $component + $name;
$spacing-form: 2%;

.#{$base} {

  &__hidden { 
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    display: block;
    position: absolute;
  }

  &__line {
    position: relative;
    margin: 0;
    border-color: transparentize($color-secondary, 0.9);
    left: -1rem;
    width: calc(100% + (2rem - 2px));
  }

  /* layout */
  &__section {
    max-width: 50rem;
    margin-bottom: $spacing-big;
    width: 100%;

    &--dashed {
      border: 2px dashed transparentize($color-secondary, 0.9);    
      padding: $spacing;
      border-radius: $border-radius;

      .#{$base}__value {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-button-background;
      }  

    }

  }

  &__wrap { 
    display: flex;
    flex-wrap: wrap;
    position: relative;
    left: -$spacing;
    width: calc(100% + #{$spacing-big});
    justify-content: space-between;
  }

  &__formWrap {
    display: flex; 
    flex-wrap: wrap;
    position: relative;
    justify-content: start; 
  }

  &__col {
    flex: 1;
    margin: 0 $spacing $spacing;

    & .cd-mod-list-block {
      font-size: 1.2rem;
      padding-left: 0;
      
      li:before {
        display: none;
      }
    } 

  }

  &__formCol {
    margin-bottom: 1.3rem;
    margin-right: #{$spacing-form};

    &--10 { flex-basis: calc(10% - #{$spacing-form}); }
    &--20 { flex-basis: calc(20% - #{$spacing-form}); }
    &--30 { flex-basis: calc(30% - #{$spacing-form}); }
    &--40 { flex-basis: calc(40% - #{$spacing-form}); }
    &--50 { flex-basis: calc(100%); }
    &--60 { flex-basis: calc(60% - #{$spacing-form}); } 
    &--70 { flex-basis: calc(70% - #{$spacing-form}); }
    &--80 { flex-basis: calc(80% - #{$spacing-form}); }
    &--90 { flex-basis: calc(90% - #{$spacing-form}); }
    &--100 { flex-basis: 100% }
    
    &--nomargin {
      margin-bottom: 0; 
    }
  }

  &__label {
    display: flex;
    font-size: 1.6rem;
    font-weight: $font-bold;
    margin-bottom: $spacing;
    justify-content: space-between;
    
    
    &--brand {
      color: $color-brand; 
    }

    &--section {
      color: $color-brand;
      line-height: 100%;
      margin-bottom: 2rem;
    }

  }

  &__subtext {
    width: 100%;
  }

  &__info {
    flex: 1;
    font-size: 1.2rem;
    margin: $spacing-small 0 $spacing;
    line-height: 100%;
  }

  &__small {
    font-size: 1.2rem;

    &--italic {
      text-transform: italic;
    }
  }

  &__text {
    @include animate(200ms);
    font-size: 1.4rem;
    font-weight: normal;

    &--inline {
      font-size: 1.2rem;
    }

    &--bold {
      font-weight: 800;
    }

  }

  &__pricebar {
    position: fixed;
    display: none;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    height: 6rem;
    box-shadow: 0 -4px 15px hsla(0, 0%, 0%, 0.1);
    padding: $spacing-small;
    background-color: $color-brand;
    color: #FFF;
    width: 100%;
    z-index: 999;
  }

  &__price {
    font-size: 1.6rem;
    font-weight: $font-bold;
    color: #FFF;
    font-variant-numeric: tabular-nums;
  }

  /* inputs */

  &__choicebutton {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    transition: all 200ms ease;
    flex-wrap: nowrap;
    text-align: center;
    margin: 0;
    padding: $spacing;
    border-radius: $border-radius;
    justify-content: center;
    align-content: center;
    flex: 1;
    color: $color-secondary;
    background-color: $color-button-background;

    &:hover, &:focus {
      svg {
        opacity: 1;
      }
    }

    svg {
      opacity: 0.1;
      transition: all 200ms ease;
      margin: auto;
      padding: $spacing;
      
      path {
        transition: all 200ms ease;
        fill: $color-secondary;
      }

    }

    &--price {
      text-align: left;
      font-size: 1.2rem;
      margin-top: auto;
      max-height: 80px;
    }

    &--small {
      margin: 0 0 $spacing-small;
      flex-direction: row;
      justify-content: flex-start;
      max-width: 100%;

      .#{$base}__small { 
        margin-left: $spacing-small;
        font-weight: normal;
      }

      .#{$base}__value {
        font-size: 1.4rem;
        margin-left: auto;
      }

      svg {
        opacity: 1;
        box-sizing: content-box;
        margin: auto 1rem auto 0;
        padding: 0;
      }

    }
   
  }

  &__value {
    font-size: 1.8rem;
    font-weight: $font-bold;
    @include animate(200ms);
  }

  input:checked + &__choicebutton {
    color: $color-brand;
    background-color: transparentize($color-brand, 0.9);
    svg {
      opacity: 1;
      path {
        fill: $color-brand;
      }
    }
  }

  input:focus + &__choicebutton {
    color: $color-brand;
    background-color: transparentize($color-brand , 0.8);
    svg {
      opacity: 1;
      path {
        fill: $color-brand;
      }
    }
  }

  input:focus + &__choicebutton {
    svg {
      opacity: 1;
    }
  }

  input:not([value=""]):not(:focus):invalid {
    border: .2rem solid #f32626;
  }

  input:not([value=""]):not(:focus):invalid + &__text {
    color: #f32626;
  }

  input:not([value=""]):valid {
    border: .2rem solid green;
  }

  input:not([value=""]):not(:focus):valid + &__text {
    color: green;
  }

  input[type="submit"] {
    border: 0 solid transparent !important;
    height: 4rem;
    margin-left: auto;
    margin-right: 2%;
  }

  &__radio {
    display: block;
    width: 100%;
    font-size: 1.4rem;
    color: transparentize($color-secondary, 0.5);
  }

  &__radioLabel {
    height: 5rem;
    background-color: #fff;
    padding: 1.5rem 2rem;
    margin-bottom: 1rem;
    margin-right: .5rem;
    color: $color-secondary;
    border-radius: 1rem;
    background: #f3f3f3;
    line-height: 100%;
    align-items: center;
    display: inline-flex;
    cursor: pointer;

    &:hover:not(:hover) {
      opacity: 0.5;
    }
  }

  input:checked + &__radioLabel {
    background: transparentize($color-brand, 0.9);
    color: $color-brand;    
  }
  
  input:focus + &__radioLabel {
    background: transparentize($color-brand, 0.9);
    color: $color-brand;    
  }

  &__select {
    width: 100%;
    height: 5rem;
    cursor: pointer;
    background-color: #FFF;
    padding: 0 $spacing;
    margin-bottom: 1rem;
    margin-right: 2%; 
    border-radius: $border-radius;
    color: $color-secondary;
    border: .2rem solid transparentize($color-secondary, 0.9);
  }

  &__input {
    width: 100%;
    height: 5rem;
    background-color: #FFF;
    padding: 1.3rem $spacing;
    margin-bottom: $spacing; 
    color: $color-secondary;
    border-radius: $border-radius;
    border: .2rem solid transparentize($color-secondary, 0.9);
    @include animate(200ms);

    &:focus {
      outline:0;
    }

    &Text {
      position: relative;
      width: 100%;
      height: 50px;
      display: block;

      &area {
        display: block;
        textarea {
          min-height: 10rem;
          margin-bottom: 0;
          &::placeholder { 
            color: rgba(44,58,94,.5); 
            font-size: 1.6rem;
          }
        }
      }

      & .#{$base}__text {
        position: absolute;
        transition: all 150ms ease;
        top: 50%;
        cursor: text;
        left: $spacing;
        line-height: 100%;
        padding: 0 .5rem;
        display: block;
        transform: translate(0, -50%);
        background-color: #FFF;
        color: transparentize($color-secondary, 0.5);
      }

    }

    &:not([value=""]) + .#{$base}__text, 
    &[type="file"] + .#{$base}__text {
      top: 0;
      color: $color-secondary !important;
      cursor: pointer;
    }

    &:focus + .#{$base}__text {
      top: 0;
      color: $color-brand;
      cursor: pointer;
    }
 
  }

}

@media (min-width: $media-tablet) {
  
  .#{$base} {

    &__formCol {
      margin-bottom: 1.3rem;

      &--50 {         
        flex-basis: calc(50% - #{$spacing-form}); 
      }

      &--nomargin {
        margin-bottom: 0;
      }

    }

    &__line {
      left: 0rem;
      width: 100%;
      margin: ($spacing-big * 2) 0;
    }

  }

}

@media (min-width: $media-desktop) {
  .#{$base} {

    &__label {
      display: flex;
      font-size: 2rem;
      font-weight: $font-bold;
      margin-bottom: $spacing-big;
      justify-content: space-between;
      margin-top: $spacing;
      line-height: 1.6rem;

      &--smaller {
        font-size: 1.6rem;
        margin-bottom: $spacing;
      }

      &--top {
        margin-top: 0;
        margin-bottom: $spacing-big;
      }    
    }
    
    &__col {
      &--bordered {
        border: .2rem solid transparentize($color-secondary, 0.9);
        border-radius: $border-radius;
        padding: $spacing;
        display: flex;
        flex-direction: column;
      }

      & .cd-mod-list-block {
        font-size: 1.6rem;
        padding-left: 2.5rem;
        
        li:before {
          display: block;
        }
      }
      
    }

    &__formCol {
      margin-bottom: 1.3rem;

      &--50 {         
        flex-basis: calc(50% - #{$spacing-form}); 
      }

      &--nomargin {
        margin-bottom: 0;
      }

    }

    &__text {
      font-size: 1.4rem;
      
      &--inline {
        font-size: 1.2rem;
      } 
    }

    &__pricebar {
      position: fixed;
      max-width: 30rem;
      right: $spacing;
      bottom: $spacing;
      left: auto;
      padding: $spacing-big;
      border-radius: $border-radius;
    }
  }
}

[data-price="color_light"],
[data-price="color_dark"],
[data-price="option_dirt"],
[data-price="option_tape"] {
  display: none;
}