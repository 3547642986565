// module name
$name: 'menu';
$base: $prefix + $component + $name;

// Mobile menu
.#{$base} {
  position: fixed;
  top: 0;
  height: $menu-bar-height;
  width: 100%;
  padding: 0 $spacing-big;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  box-shadow: $menu-dropshadow;
  background-color: #FFF;

  &__container {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: -100%;
    opacity: 0;
    background: #FFF;
    height: calc(100vh - #{$menu-bar-height});
    width: 100%;
    z-index: 8;
    margin-top: $menu-bar-height;
    transition: all 200ms ease;
    overflow: auto;
    padding-bottom: 5rem;
  }

  .is-open & {

    &__container {
      left: 0;
      opacity: 1;
      max-width: $menu-max-width;
    }

  }

  &.is-open-search & {
    &__logo {
      opacity: 0;
    }
  }

  &__item {
    display: flex;
    position: relative;
    flex-direction: row;
    flex: 1;
    font-size: 1.4rem;
    max-height: $menu-item-max;
    min-height: $menu-item-min;
    transition: all 200ms ease;

    &--right {
      margin: auto;
      max-width: 20rem;
      &::after {
        display: none;
      }
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
      margin: auto $spacing-small auto auto;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: .1rem;
      width: 100%;
      background-color: rgba(0,0,0,.1);
    }
  }

  &__link {
    text-decoration: none;
    white-space: nowrap;
    padding: 0 $spacing-big;
    color: #000;
    margin: auto auto auto $spacing;
    width: 100%;

    &:hover {
      @include animate(200ms);
      color: $color-brand;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &--active {
      font-weight: $font-bold;
    }

  }

  &__logo {
    width: $menu-logo-width;
    margin: 0 auto 0;
  }

  &__link {
    margin: auto;
  }

  &__icon {

    &::after {
      content: 'Menu';
      margin-left: .5rem;
      color: $color-brand;
      position: absolute;
      left: 1.5rem;
      line-height: 46px;
    }

    svg {
      height: 100%;
      width: 100%;
      padding: $spacing-small;
    }

    &--menu svg {
      position: absolute;
      top: 0;
      width: 1.5rem;
      height: 100%;
      padding: 0;
    }

  }

  &__button {
    transition: all 200ms ease;
    position: relative;
    align-items: center;
    height: 100%;
    width: 4rem;
    background-color: $color-brand-darker;
    border: 0;
    border-radius: $border-radius;
    -webkit-appearance: none;

    &--menu {
      position: absolute;
      z-index: 2;
      left: 1rem;
      width: 4rem;
      margin-left: 0;
      background-color: transparent;
      color: #FFF;
      font-family: $font-special-family;
      font-size: 1.2rem;
      font-weight: $font-bold;
      margin-left: auto;
    }

    &--left {

    }

    &--right {

    }

  }

}

@media screen and (min-width: $media-desktop) {
  .#{$base} {
    &__item {
      margin-right: 1rem;
      margin-left: 1rem;
    }
  }
}


@media screen and (min-width: $media-desktop) {
  .#{$base} {
    position: absolute;
    justify-content: flex-end;
    margin-bottom: 4rem;
    left: 0;
    right: 0;
    max-width: $grid-container;
    margin: auto;
    height: 8.5rem;
    box-shadow: none;

    &__container {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: flex-end;
      left: 0;
      opacity: 1;
      margin-top: 0;
      overflow: inherit;
      height: 100%;
      background-color: transparent;
      padding-bottom: 0;
    }

    &__item {
      list-style: none;
      padding: 0;
      margin-left: 1rem;
      margin-right: 1rem;
      position: relative;
      display: flex;
      flex: none;
      top: 0;
      width: auto;
      position: relative;
      height: 100%;
      margin-top: 3px;

      &:last-of-type {
        margin-right: 0;
      }

      &--right {
        margin: 0 auto 0 0;
        max-width: none;
      }

      &--cta {
        font-weight: $font-bold;
        background-color: transparent;
        
        a {
          color: $color-brand;
        }
      }

      a {
        padding: 0;
      }

      &:hover {
        color: $color-brand; 
      }

      & svg {
        display: none;
      }

      &::after { display: none; }

    }

    &__link {
      font-size: 1.6rem;
    }

    &__button--menu {
      display: none;
    }

    &__button {

      &--right {
        margin-left: auto;
      }

    }

    &__logo {
      opacity: 1 !important;
      width: 20rem + ($spacing-big * 2);
      margin: 0 $spacing-big 0 0;
    }

  }
}
