// module name
$name: 'boxed-filter';
$base: $style-prefix + $module + $name;

.#{$base} {
  @include box;
  background-color: #FFF;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  z-index: 95;
  position: relative;
  padding: $spacing-big $spacing-big 0;

  &__subtitle {
    color: $color-brand;
    font-size: $font-subheading-size;
    display: block;
    line-height: 1rem;
  }

  &__title {
    color: $color-brand;
    font-size: 1.6rem;
    font-weight: $font-strong;
    margin-bottom: $spacing;
  }

  &__bottom {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-big;
  }

  &__amount {
    flex: 1 1 0;
    color: $color-brand;
    font-weight: $font-strong;
    padding-right: $spacing;
    white-space: nowrap;
    font-size: 1.8rem;
  }

  &__button {
    flex: 1 1 0;
    @include button();
    padding: $spacing $spacing-big;
    font-size: 1rem;
    white-space: nowrap;
  }

}


@media screen and (min-width: $media-desktop) {

  .#{$base} {
  	height: 32rem;
    padding: ($spacing-big * 2) ($spacing-big * 2) 0;

    &__subtitle {
      font-size: 2.1rem;
      line-height: 2.8rem;
    }

    &__title {
      font-size: 3rem;
    }

    &__bottom {
      margin-top: auto;
      margin-bottom: $spacing-big * 2;
    }

  }

}
