// component name
$name: 'blog';
$base: $style-prefix + $component + $name;

.#{$base} {
  display: flex;
  flex-direction: column;

  &__title {
    flex: 1 1 0;
    flex-basis: 100%;
    font-size: $module-header-small;
    font-weight: $font-strong;
    margin-bottom: $spacing-big;
    margin-top: 0;
    text-align: center;
    color: $color-brand;
  }

  &__container {
    display: flex;
    flex-direction: column;
    @include grid-container;
  }

  &__button {
    margin: $spacing-section 0;
    @include button($width: auto);
    margin: $spacing auto;
  }

}

@media (min-width: $media-desktop) {

  .#{$base} {

    &__title {
      font-size: $module-header-big;
    }

    &__subtitle {
      color: $color-brand;
      font-size: $font-subheading-size;
      display: block;
      line-height: 1rem;
    }

    &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

  }

}
