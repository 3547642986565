// component name
$name: 'gallery';
$base: $style-prefix + $component + $name;

.#{$base} {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-bottom: $spacing-section;
  mask-image: linear-gradient(to right, rgba(30,87,153,0) 0%, rgba(230,48,255,1) 10% ,rgba(229,0,255,1) 90%, rgba(125,185,232,0) 100%);
  -webkit-mask-image:  linear-gradient(to right, rgba(30,87,153,0) 0%, rgba(230,48,255,1) 10% ,rgba(229,0,255,1) 90%, rgba(125,185,232,0) 100%);

  &__image {
    display: block;
    padding: $spacing;
    width: 100%;
    height: 32%;
    object-fit: cover;
  }

  &__heading {
    display: block;
    font-size: 1.6rem;
    margin-bottom: $spacing-big;
    text-align: center;
    font-weight: $font-bold;
  }

  &__name {
    text-align: center;
    font-weight: $font-bold;    
  }

  &__description {
    text-align: center;
  }

  &--grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 $spacing-section;
    mask-image: none;
    -webkit-mask-image: none;

    .#{$base}__item {
      flex: 1;
      order: 3;
      flex-basis: 100%;
      max-height: none;
    }

    .#{$base}__item:nth-of-type(-n+3) {
      order: 1;
    }

  }

  &--grid & {

    &__image {
      height: 100%;
    }

  }

  /* Slick slider */
  .slick-slide {
    @include animate; 
  }

  .slick-current {
    transform: scale(1.05);
  }

  .slick-track {
    padding-bottom: $spacing-section;
  }

} 

@media (min-width: $media-desktop) {
  .#{$base} {

    &__heading {
      font-size: calc(1.4rem + .15vw*(6 - 2)); 
    }
    
    &--slider {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
    }

    &--grid {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 $spacing-section;
      padding-left: $spacing;

      .#{$base}__item {
        flex: 1;
        order: 1;
        flex-basis: 33%;
        max-height: 30rem;
        max-width: 33%;
      }
    }

    /* Slick slider */
    .slick-current {
      transform: scale(1);
    }
  }
}