// module name
$name: 'background';
$base: $prefix + $layout + $name;

.#{$base} {
  padding: $spacing-section 0;
  background-color: $color-background;
  margin-bottom: $spacing-section;

  &--mobile {
    background-color: transparent;
  }

  &--brand {
    background-color: $color-brand;
  }

  &--dark {
    background-color: $color-brand-darker;
  }

  &--light {
    background-color: hsla(0, 0%, 100%, 0.4);
  }

  &--nomargin {
    margin-bottom: 0;
  }

}

@media (min-width: $media-desktop) {
  .#{$base} {
    padding: ($spacing-section * 3) 0;
    margin-bottom: $spacing-section * 3;

    &--mobile {
      background-color: lighten($color-gray, 80);
    }

    &--nomargin {
      margin-bottom: 0;
    }


  }
}
