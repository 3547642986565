$plate-prefix: 'plate--';
$plate-viewport-s: 768px;
$plate-viewport-m: 992px;
$plate-viewport-l: 1200px;

@mixin plate_heading {
    @for $i from 2 through 6 {
      h#{$i} {
      position: relative;
      display: inline-block;
      margin-top: 0;
      font-weight: 700;
      margin-top: $spacing;
      margin-bottom: $spacing / 2;
      
      
      @if $i < 5 {
        font-size: calc( #{$font-base-fontsize} + .15vw * (6 - #{$i}));
      } @else {
        font-size: calc( #{$font-base-fontsize} + .15vw);      
      }
      
    }    
  }
}