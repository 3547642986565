// module name
$name: 'pagination';
$base: $prefix + $component + $name;

.#{$base} {
  display: flex;
  padding: 0 $spacing;
  margin-bottom: $spacing-section;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  &__container {
    display: flex;
    flex-direction: row;
    list-style: none;
    width: 100%;
    justify-content: center;
  }

  &__item {
    padding: $spacing;

    &--active &Link {
      color: $color-brand;
    }

    &Link {
      font-size: 1.4rem;
      font-family: $font-special-family;
      text-decoration: none;
      font-weight: $font-bold;
      color: $color-primary;
    }

  }



  &__button {
    display: flex;
    align-items: center;

    &--prev {
      margin-right: auto;
      padding-left: 0;

      svg {
        margin: 0 $spacing;
      }
    }
    &--next {
      margin-left: auto;
      padding-right: 0;

      svg {
        margin: 0 $spacing;
      }

    }

    @include button($button-color-primary, auto);

    &--disabled {
      @include button($button-color-disabled, auto);
    }

    svg {
      max-width: 1.2rem;
      max-height: 1.2rem;
    }

  }

}

@media (min-width: $media-desktop) {
  .#{$base} {
    padding: $spacing-big;
  }
}
