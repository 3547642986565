// module name
$name: 'usps';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: none;
}

@media (min-width: $media-tablet) {
  
  .#{$base} {
    display: block;
    line-height: 6rem;
    padding-top: 0;
    margin-bottom: $spacing-big;
    max-height: 6rem;
    overflow: hidden;

    &--right {
      text-align: right;
    }

    &__item {
      display: inline-block;
      color: #FFF;
      margin-left: $spacing-big;

      &::before {
        content: '\f00c';
        font-family: 'FontAwesome';
        color: #FFF;
        position: relative;
        display: inline-block;
        font-size: 1.8rem;
        margin-right: .5rem;
        border-radius: 100%;
      }
      
    }
  }

}