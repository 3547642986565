// module name
$name: 'socials';
$base: $style-prefix + $module + $name;

.#{$base} {


  &__item {
    margin-right: $spacing-small;
    @include animate(250ms);

    @media (min-width: $media-desktop) {
      margin-right: $spacing;
    }

    &:hover {
      opacity: .8;
    }

    i {
      font-size: 3.2rem !important;
    }

    @media (min-width: $media-desktop) {
      i {
        font-size: 2rem !important;
      }
    }

  }

}
