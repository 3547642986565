// component name
$name: 'service';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: #FFF;
  padding: $spacing-big $spacing-big 0;
  border-radius: $border-radius;
  line-height: $font-base-lineheight;
  z-index: 2;
  margin: $grid-gap $grid-gap $spacing-big;
  order: 2;
  height: 30rem;
  flex: 1;  

  @include shadow;

  &__title {
    display: block;
    font-size: 1.8rem;
    font-weight: $font-bold;
    margin-bottom: 1.5rem;
    color: $font-base-color;
  }

}

@media (min-width: $media-desktop) {
  .#{$base} {
    order: 1;
    max-width: calc(33% - (#{$spacing} * 2));
    height: calc(30rem - #{$spacing-big});
  }

}
