// module name
$name: 'big-heading';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: block;
  font-weight: $font-bold;
  font-size: 2.7rem;
  line-height: 100%;
  width: 100%;
  margin: 0 0 $spacing-big;

  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }

}

@media (min-width: $media-desktop) {
  .#{$base} {
    font-size: 4rem;
    margin: 0 0 $spacing-section;
  }

}