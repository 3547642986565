// component name
$name: 'list-block';
$base: $style-prefix + $module + $name;

.#{$base} {
  position: relative;
  padding-left: 2.5rem;
  margin-bottom: $spacing-big;
  margin-top: -$spacing;

  &__title {
    display: block;
    font-weight: $font-bold;
    margin-bottom: $spacing-big;
  }

  &__item {
    position: relative;
    margin-bottom: .2rem;
    &::before {
      content: '';
      font-family: 'FontAwesome';
      color: $color-brand;
      display: block;
      top: 0;
      font-size: 1.8rem;
      width: .8rem;
      border-radius: 100%;
      position: absolute;
      left: -2.5rem;
    }
  }

  &--normal &__item::before {    
    content: '\f054';
    top: -.1rem;
    font-size: 1.6rem;
  }

  &--positive &__item::before {    
    content: '\f00c';
    top: -.1rem;
  }

  &--negative &__item::before {    
    content: '\f00d';
    top: -.1rem;
  }

  &--plus &__item::before {    
    content: '\f067';
    top: 0;
  }

  &--min &__item::before {    
    content: '\f068';
    top: -.1rem;
  }
  
}