@mixin card {

  &Link {
    display: block;
    width: 100%;
    text-decoration: none;
    z-index: 10;
    font-size: $card-title;
    color: $card-title-color;
    font-family: $font-special-family;
    line-height: $font-heading-lineheight;
    text-shadow: $font-shadow;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

}

@mixin card-desktop {

  // IE 11 styling.
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: inline-block;
    width: 24%;
    height: 200px !important;
    margin: 0.5%;
  }

  &Link {
    font-size: $card-title * $desktop-factor;
    padding: 0;

    // IE 11 styling
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      padding: 0.2rem;
      position: absolute;
      bottom: 0;
      z-index: 10;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      // IE 11 styling
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        transform: scale(100);
      }
    }
  }
}
